@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

:root {
    --header-height: 6.5vh;
    --sidebar-width: 5vw;
    --footer-height: 2.5vh;
    --sidebar-icon-size: 3vh;
    --logo-base-height: 2vh;
    --sidebar-fontSize: 1rem;
    --notification-icon-base-height: 5vh;
    --table-gap: 1rem;
    --table-header-height: 3rem;
    --title-container-height: 0rem;
    --pagination-gap: 0rem;
    --wallet-container-width: 30vw;
    --chat-message-input-height: 3rem;
    --chat-list-width: 1fr;
    --chat-box-width: 3fr;
    --chat-detail-width: 0vh;
    --chat-containers-header: 5.2vh;
    --chat-tabs-height: 5vh;
    --chat-sub-tabs-height: 0vh;
    --chat-big-button-height: 0vh;
    --audio-right-color: #fff;
    --audio-left-color: #eaf4ff;
    --create-account-height: 10vh;
    --onboard-submit-buttons-div: 8vh;
    --sp-onboard-progress-height: 8vh;
    --ticket-close-feasibility-action-detail: 13vh;
    --gridLayout: 'personal bussiness' 'bank  bussiness';
    --modal-header-height: 6rem;
    --project-sidebar-header: 10vh;
    --announcement-bar-height: 0px;
}

* {
    box-sizing: border-box;
    font-family: 'Roboto', sans-serif;
    outline: none !important;
    scrollbar-width: thin;
}

/* width */

::-webkit-scrollbar {
    width: 4px !important;
    height: 4px !important;
    cursor: pointer !important;
    position: absolute !important;
    top: 0 !important;
}

/* Track */

::-webkit-scrollbar-track {
    background: #fff;
    cursor: pointer !important;
    position: sticky !important;
    top: 0 !important;
}

/* Handle */

::-webkit-scrollbar-thumb {
    background: #999999;
    cursor: pointer !important;
}

/* Handle on hover */

::-webkit-scrollbar-thumb:hover {
    background: #999999;
    cursor: pointer !important;
}

input:checked ~ .dot {
    transform: translateX(100%);
    background-color: #12355f;
}
.mentions-input__input textarea::placeholder {
    color: #888;

    padding-top: 3px;
}

input:checked ~ .aatext {
    color: #12355f;
}

input:checked ~ .bbtext {
    color: #888888;
}

.custom-file-input {
    color: transparent;
    opacity: 1 !important;
    position: initial !important;
    height: 100% !important;
}

.custom-file-input::-webkit-file-upload-button {
    visibility: hidden;
}

.risetickets input[type='file'] {
    opacity: 0;
}

.custom-file-input::before {
    content: '';
    display: inline-block;
    background-image: url('./img/camera-profile.png');
    background-repeat: no-repeat;
    background-position: center;
    background-color: #f48a21;
    outline: none;
    border-radius: 50%;
    white-space: nowrap;
    user-select: none;
    cursor: pointer;
    text-shadow: 1px 1px #fff;
    font-weight: 700;
    font-size: 5pt;
    height: 28px;
    width: 28px;
    position: absolute !important;
    bottom: 10px !important;
    right: 0 !important;
}

.Flipped .Content {
    transform: rotateX(180deg);
    -ms-transform: rotateX(180deg);
    /* IE 9 */
    -webkit-transform: rotateX(180deg);
    /* Safari and Chrome */
}

.Flipped {
    transform: rotateX(180deg);
    -ms-transform: rotateX(180deg);
    /* IE 9 */
    -webkit-transform: rotateX(180deg);
    /* Safari and Chrome */
}

.action-fix th:last-child {
    position: sticky;
    right: 0;
}

.action-fix td:last-child {
    position: sticky;
    right: 0;
}

.Content th:first-child {
    position: sticky;
    right: 0;
}
input[type='text']:focus {
    box-shadow: none;
}
@media (max-width: 767px) {
    .help-table tr:nth-child(even) {
        background-color: #f5f5f5 !important;
    }

    .help-table tr:nth-child(even) {
        background-color: #f5f5f5;
    }
}

@media (min-width: 768px) {
    .my-table th:last-child {
        position: sticky;
        right: 0;
        background-color: #fff;
    }

    .my-table td:last-child {
        position: sticky;
        right: 0;
        background-color: #fff;
    }

    .my-table td {
        max-height: 170px;
        height: 170px;
    }
}

@media (min-width: 1024px) {
    .my-table th:nth-child(1) {
        position: sticky;
        left: 0;
        background-color: #fff;
    }

    .my-table td:nth-child(1) {
        position: sticky;
        left: 0;
        background-color: #fff;
        z-index: 1;
    }

    .my-table th:nth-child(2) {
        position: sticky;
        left: 160px;
        background-color: #fff;
    }

    .my-table td:nth-child(2) {
        position: sticky;
        left: 160px;
        background-color: #fff;
        z-index: 1;
    }

    .my-table th:nth-child(3) {
        position: sticky;
        left: 320px;
        background-color: #fff;
    }

    .my-table td:nth-child(3) {
        position: sticky;
        left: 320px;
        background-color: #fff;
        z-index: 1;
    }

    .my-table th:nth-child(4) {
        position: sticky;
        left: 512px;
        background-color: #fff;
    }

    .my-table td:nth-child(4) {
        position: sticky;
        left: 512px;
        background-color: #fff;
        z-index: 1;
    }
}

.paginatedTable {
    --pagination-gap: 3.5rem;
}

.nonPaginatedTable {
    --pagination-gap: 1.25rem;
}

.title-2 {
    --title-container-height: 2rem;
}

.title-3 {
    --title-container-height: 3rem;
}

.title-0 {
    --title-container-height: 0rem;
}

.title-1 {
    --title-container-height: 1rem;
}

.title-2 {
    --title-container-height: 2rem;
}

.title-3 {
    --title-container-height: 3rem;
}

.title-4 {
    --title-container-height: 4rem;
}

.title-5 {
    --title-container-height: 5rem;
}

.title-6 {
    --title-container-height: 6rem;
}

.title-7 {
    --title-container-height: 7rem;
}

.title-8 {
    --title-container-height: 8rem;
}

.title-8_5 {
    --title-container-height: 8.5rem;
}

.title-9 {
    --title-container-height: 9rem;
}

.title-10 {
    --title-container-height: 10rem;
}

.title-11 {
    --title-container-height: 11rem;
}

.title-12 {
    --title-container-height: 12rem;
}

.title-13 {
    --title-container-height: 13rem;
}

.title-14 {
    --title-container-height: 14rem;
}

.header {
    height: var(--header-height);
    background-color: white;
    padding: 0% 1% 0% 1%;
    border: 1px solid rgb(213, 211, 211);
    top: var(--announcement-bar-height);
}

.onboardHeader {
    height: var(--header-height);
    background-color: white;
    border: 1px solid rgb(213, 211, 211);
}

.createAccountHeight {
    height: var(--create-account-height);
}

.onboardLayoutFooterHeight {
    height: calc(var(--create-account-height) + var(--header-height));
}

.onboardChildiv {
    height: calc(100vh - (var(--create-account-height) + var(--header-height) + var(--create-account-height) + var(--header-height)));
}

.onboardForm {
    height: calc(100vh - (var(--create-account-height) + var(--header-height) + var(--create-account-height) + var(--header-height) + var(--onboard-submit-buttons-div)));
}

.onboardSubmitButton {
    height: var(--onboard-submit-buttons-div);
}

.spProgrssBar {
    height: var(--sp-onboard-progress-height);
}

.spForm {
    height: calc(
        100vh -
            (
                var(--create-account-height) + var(--header-height) + var(--create-account-height) + var(--header-height) + var(--onboard-submit-buttons-div) +
                    var(--sp-onboard-progress-height)
            )
    );
}

.headerLogo {
    height: calc(var(--header-height) - var(--logo-base-height));
}

.profileImage {
    width: calc(var(--header-height) - var(--footer-height));
    height: calc(var(--header-height) - var(--footer-height));
    border-radius: 100%;
    object-fit: cover;
}

.notificationIcon {
    width: calc(var(--header-height) - var(--notification-icon-base-height));
    height: calc(var(--header-height) - var(--footer-height));
}

.notificationBadge {
    top: 20%;
    left: 25%;
    right: 30%;
}

.sidebar {
    height: calc(100vh - (var(--header-height) + var(--footer-height) + var(--announcement-bar-height)));
    top: calc(var(--header-height) + var(--announcement-bar-height));
    width: var(--sidebar-width);
    background-color: white;
    border: 1px solid rgb(213, 211, 211);
    box-sizing: border-box;
}

.fullscreen-modal {
    height: calc(100vh - (var(--header-height) + var(--footer-height)));
    width: calc(100vw - (var(--sidebar-width)));
}

.fullscreen-position {
    margin-top: var(--header-height);
    margin-left: var(--sidebar-width);
}

.modal-header {
    height: var(--modal-header-height);
}

.sidebarFont {
    font-size: var(--sidebar-fontSize);
}

.sidebarIcons {
    width: var(--sidebar-icon-size);
}

.mainContainer {
    position: fixed;
    height: calc(100% - (var(--header-height) + var(--footer-height) + var(--announcement-bar-height)));
    width: calc(100vw - (var(--sidebar-width)));
    top: calc(var(--header-height) + var(--announcement-bar-height));
    left: var(--sidebar-width);
    display: flex;
}
.mainContainerMobileView {
    position: fixed;
    height: calc(100% - (var(--header-height) + var(--footer-height) + var(--announcement-bar-height)));
    width: 100vw;
    top: calc(var(--header-height) + var(--announcement-bar-height));
    display: flex;
}
.subContainer {
    width: 100%;
    height: 100%;
    flex: 3;
    background-color: #f8f8f8;
}

.parentTest {
    height: calc(100vh - var(--footer-height));
    background-color: #f48a21;
}

.footer {
    height: var(--footer-height);
    background-color: white;
}

.tableContainer {
    background-color: white;
    box-sizing: border-box;
    height: calc(100% - (var(--title-container-height) + var(--pagination-gap)));
    width: 100%;
}

.tableContainerEditPayout {
    background-color: white;
    box-sizing: border-box;
    height: calc(100% - (var(--title-container-height) + 4rem));
    width: 100%;
}

.new_tickets_card_container {
    box-sizing: border-box;
    height: calc(100% - (var(--title-container-height) + var(--pagination-gap)));
    width: 100%;
}

.passbookContainer {
    box-sizing: border-box;
    height: calc(100% - (var(--title-container-height) + var(--pagination-gap)));
    width: 100%;
}

.walletContainer {
    width: var(--wallet-container-width);
}

.tableBody {
    overflow-y: auto;
    height: calc(100% - (var(--table-header-height)) + var(--title-container-height) + var(--pagination-gap));
    border-radius: inherit;
}

.virTableBody {
    height: calc(100% - (var(--table-header-height)) + var(--title-container-height) + var(--pagination-gap));
    border-radius: inherit;
}

.ticket_table {
    height: calc(100% - (var(--table-header-height)) + var(--title-container-height) + var(--pagination-gap));
    border-radius: inherit;
}

.formTable {
    --table-header-height: auto;
}

.tableHeader {
    height: var(--table-header-height);
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
}

.frozenTableHeader {
    background-color: white !important;
    font-weight: 600 !important;
}

.passbook {
    overflow-y: scroll;
    height: calc(70vh - (var(--header-height) + var(--footer-height)));
}

.titleContainer {
    height: var(--title-container-height);
}

.sidedrawer {
    background: rgb(255, 255, 255);
    flex: 1.2;
    height: 100%;
    box-sizing: border-box;
}

.payoutSidedrawer {
    min-width: 37rem;
    width: 37rem;
}

.divideContainerInThree {
    --chat-list-width: 1fr;
    --chat-box-width: 2fr;
    --chat-detail-width: 1fr;
}

.divideContainerInTwo_3_1 {
    --chat-list-width: 0;
    --chat-box-width: 3fr;
    --chat-detail-width: 1fr;
}

.divideContainerInTwo_1_3 {
    --chat-list-width: 1fr;
    --chat-box-width: 3fr;
    --chat-detail-width: 0;
}
.mobileChatListView {
    --chat-list-width: 3fr;
    --chat-box-width: 0;
    --chat-detail-width: 0;
}

.mobileChatMessageView {
    --chat-list-width: 0;
    --chat-box-width: 3fr;
    --chat-detail-width: 0;
}
.mobileUserDetailView {
    --chat-list-width: 0;
    --chat-box-width: 0;
    --chat-detail-width: 1fr;
}
.chatContainerHeader {
    height: var(--chat-containers-header);
    background-color: white;
    border-bottom: 1px solid rgb(212, 207, 207);
    width: 100%;
}

.chatContainerContent {
    height: calc(100% - var(--chat-containers-header));
}
.dropdown-items {
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
    /* width: 85%; */
    position: absolute;
    margin-left: 0.09rem;
    background-color: white;
    display: flex;
    flex-direction: column;
    border-radius: 4px;
}
.chatContainer {
    height: 100%;
    width: inherit;
    display: grid;
    grid-template-columns: var(--chat-list-width) var(--chat-box-width) var(--chat-detail-width);
    background-color: #f8f8f8;
}

.chatCard {
    height: calc(100vh - (var(--header-height) + var(--footer-height)));
    overflow-y: auto;
}

.chat-tabs {
    height: var(--chat-tabs-height);
}

.set-chat-big-button {
    --chat-big-button-height: 6vh;
}

.chat-big-button {
    height: var(--chat-big-button-height);
}

.set-sub-tabs {
    --chat-sub-tabs-height: 6vh;
}

.chat-sub-tabs {
    height: var(--chat-sub-tabs-height);
}

.chat-list {
    height: calc(100% - (var(--chat-tabs-height) + var(--chat-big-button-height) + var(--chat-sub-tabs-height) + var(--chat-containers-header)));
}

.chat-notifications {
    height: calc(100% - var(--chat-sub-tabs-height));
}

.gridHeight {
    height: calc(100vh - (var(--header-height) + var(--footer-height)));
}

.groupDetailsHeight {
    height: calc(100vh - (var(--header-height) + var(--footer-height) + var(--chat-containers-header)));
}

.groupDetailsMediaHeight {
    height: calc(100vh - (var(--header-height) + var(--footer-height) + var(--chat-containers-header) + 5vh));
}

.chatInputBar {
    border-top: 1px solid rgb(212, 207, 207);
    width: 100%;
    background-color: white;
    min-height: var(--chat-message-input-height);
}

.audioBgWhite::-webkit-media-controls-play-button,
.audioBgWhite::-webkit-media-controls-panel {
    background-color: var(--audio-right-color);
}

.audioLeftMessage::-webkit-media-controls-play-button,
.audioLeftMessage::-webkit-media-controls-panel {
    background-color: var(--audio-left-color);
}

.attachment:hover .attachmentGlassEffect {
    background: rgba(255, 255, 255, 0.06);
    backdrop-filter: blur(2px);
    -webkit-backdrop-filter: blur(2px);
}

.attachment:hover .hidden {
    display: flex;
}

.messagesideComponent:hover .messageDropdown {
    display: flex;
    justify-content: center;
    align-items: center;
}

.messagesideComponent:hover .left_right_message {
    visibility: visible;
}

.messagesideComponent code {
    line-height: 110%;
    background-color: #eee;
    padding: 0.3rem 0.6rem;
    letter-spacing: -0.05rem;
    word-break: normal;
    display: block;
    border-radius: 3px;
    overflow-x: auto;
}

.animateFade {
    opacity: 1;
    transform: translateY(0);
}

.table_td :hover .table_tr {
    background-color: #dddddd !important;
}

.rounded-gradient-borders {
    width: 100%;
    height: 100%;
    border: double 10px transparent;
    border-radius: 50%;
    background-image: linear-gradient(white, white), radial-gradient(circle at left, rgb(255, 0, 0), #f6ff00);
    background-origin: border-box;
    background-clip: content-box, border-box;
    display: inline-block;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

.flip-horizontally {
    transform: scaleX(-1);
}

.slide-left {
    animation: 1s slide-left;
}

@keyframes slide-left {
    from {
        margin-left: 100%;
    }

    to {
        margin-left: 0%;
    }
}

.slide-right {
    animation: 1s slide-right;
}

@keyframes slide-right {
    from {
        margin-left: -100%;
    }

    to {
        margin-left: 0%;
    }
}

.onBoadingBody {
    height: calc(100vh - (var(--header-height)));
}

.profile_personal_details {
    grid-area: personal;
    border-radius: 7px;
}

.profile_bank_details {
    grid-area: bank;
    border-radius: 7px;
}

.profile_business_details {
    grid-area: bussiness;
    border-radius: 7px;
}

.profile_ratecard_details {
    grid-area: ratecard;
    border-radius: 7px;
}

.profileContainer {
    height: calc(100% - var(--title-container-height));
}

* {
    box-sizing: border-box;
    font-family: 'Roboto', sans-serif;
    outline: none !important;
}

::-webkit-scrollbar {
    width: 4px !important;
    height: 4px !important;
    cursor: pointer !important;
    position: absolute !important;
    top: 0 !important;
}

.tableContainer ::-webkit-scrollbar {
    width: 0.6rem !important;
    height: 4px !important;
    cursor: pointer !important;
    position: absolute !important;
    top: 0 !important;
}

.tableContainer .BaseTable__table-frozen-left ::-webkit-scrollbar {
    width: 0 !important;
}

::-webkit-scrollbar-track {
    background: #fff;
    cursor: pointer !important;
    position: sticky !important;
    top: 0 !important;
}

::-webkit-scrollbar-thumb {
    background: #999;
    cursor: pointer !important;
}

::-webkit-scrollbar-thumb:hover {
    background: #999;
    cursor: pointer !important;
}

input:checked ~ .dot {
    transform: translateX(100%);
    background-color: #12355f;
}

input:checked ~ .aatext {
    color: #12355f;
}

input:checked ~ .bbtext {
    color: #888;
}

.custom-file-input {
    color: transparent;
    opacity: 1 !important;
    position: initial !important;
    height: 100% !important;
}

.custom-file-input::-webkit-file-upload-button {
    visibility: hidden;
}

.custom-file-input::before {
    content: '';
    display: inline-block;
    background-image: url('./img/camera-profile.png');
    background-repeat: no-repeat;
    background-position: center;
    background-color: #f48a21;
    outline: none;
    border-radius: 50%;
    white-space: nowrap;
    user-select: none;
    cursor: pointer;
    text-shadow: 1px 1px #fff;
    font-weight: 700;
    font-size: 5pt;
    height: 28px;
    width: 28px;
    position: absolute !important;
    bottom: 10px !important;
    right: 0 !important;
}

.risetickets input[type='file'] {
    opacity: 0;
}

.Flipped {
    transform: rotateX(180deg);
    -ms-transform: rotateX(180deg);
    -webkit-transform: rotateX(180deg);
}

.Flipped .Content {
    transform: rotateX(180deg);
    -ms-transform: rotateX(180deg);
    -webkit-transform: rotateX(180deg);
}

.action-fix th:last-child {
    position: sticky;
    right: 0;
}

.action-fix td:last-child {
    position: sticky;
    right: 0;
}

.Content th:first-child {
    position: sticky;
    right: 0;
}

.tab-button-container :first-child {
    border-radius: 5px 0 0 5px;
}

.tab-button-container :last-child {
    border-radius: 0 5px 5px 0;
}

.onHoverShowCross:hover .showCross {
    display: block;
}

@media (max-width: 767px) {
    :root {
        --sidebar-width: 15vw;
    }
    .help-table tr:nth-child(even) {
        background-color: #f5f5f5 !important;
    }

    .help-table tr:nth-child(even) {
        background-color: #f5f5f5;
    }
}

@media (min-width: 768px) {
    .my-table th:last-child {
        position: sticky;
        right: 0;
        background-color: #fff;
    }

    .my-table td:last-child {
        position: sticky;
        right: 0;
        background-color: #fff;
    }

    .my-table td {
        max-height: 170px;
        height: 170px;
    }
}

@media (min-width: 1024px) {
    .my-table th:nth-child(1) {
        position: sticky;
        left: 0;
        background-color: #fff;
    }

    .my-table td:nth-child(1) {
        position: sticky;
        left: 0;
        background-color: #fff;
        z-index: 1;
    }

    .my-table th:nth-child(2) {
        position: sticky;
        left: 128px;
        background-color: #fff;
    }

    .my-table td:nth-child(2) {
        position: sticky;
        left: 128px;
        background-color: #fff;
        z-index: 1;
    }

    .my-table th:nth-child(3) {
        position: sticky;
        left: 240px;
        background-color: #fff;
    }

    .my-table td:nth-child(3) {
        position: sticky;
        left: 240px;
        background-color: #fff;
        z-index: 1;
    }

    .my-table th:nth-child(4) {
        position: sticky;
        left: 400px;
        background-color: #fff;
    }

    .my-table td:nth-child(4) {
        position: sticky;
        left: 400px;
        background-color: #fff;
        z-index: 1;
    }
}

.react-datepicker {
    font-size: 1rem !important;
}

.react-datepicker__time-container--with-today-button {
    right: -85px !important;
}

.react-datepicker-popper {
    z-index: 50 !important;
}

.MuiPaper-root.alert-full .MuiAlert-message.css-acap47-MuiAlert-message {
    width: 100% !important;
}

.text-scogoAmber {
    color: #f48a21;
}

.border-scogoAmber {
    border-color: #f48a21;
}

.bg-scogoAmber {
    border-color: #f48a21;
}

.closeFeasibiblityDetails {
    height: var(--ticket-close-feasibility-action-detail);
}

.closeFeasibiblityForm {
    height: '40rem';
}

.closeFeasibiblityContainer {
    box-sizing: border-box;
    height: calc(100% - (var(--title-container-height) + var(--pagination-gap)));
    width: 100%;
    --pagination-gap: 1.25rem;
}

.ticketCFActionTable {
    height: calc((100% - (var(--table-header-height)) + var(--ticket-close-feasibility-action-detail) + var(--title-container-height) + var(--pagination-gap)));
    overflow-y: auto;
    border: 2px solid red;
    overflow: auto;
}

.ticketCFActionTable tbody {
    height: calc((100% - (var(--table-header-height)) + var(--ticket-close-feasibility-action-detail) + var(--title-container-height) + var(--pagination-gap)));
    border: 2px solid green;
    overflow: auto;
}

.approvePOContainer {
    box-sizing: border-box;
    height: calc(100% - (var(--title-container-height) + var(--pagination-gap)));
    width: 100%;
    --pagination-gap: 1.25rem;
}

.approvePOButtons {
    box-sizing: border-box;
    height: var(--onboard-submit-buttons-div);
    width: 100%;
}

.approvePOForm {
    box-sizing: border-box;
    height: calc(100% - (var(--title-container-height) + var(--pagination-gap)));
    width: 100%;
}

.grid-container {
    display: grid;
    grid-auto-columns: 1fr;
    grid-auto-flow: column;
}

.edit-ticket {
    display: grid;
    grid-template-areas:
        'et-ticket et-ticket et-site et-site et-partner'
        'et-ticket et-ticket et-site et-site et-partner'
        'et-ticket et-ticket et-site et-site et-partner'
        'et-ticket et-ticket et-site et-site et-partner'
        'et-ticket et-ticket et-site et-site et-partner'
        'et-ticket et-ticket et-asset et-asset et-docket'
        'et-ticket et-ticket et-asset et-asset et-docket'
        'et-misc et-misc et-asset et-asset et-docket'
        'et-misc et-misc et-asset et-asset et-docket'
        'et-misc et-misc et-asset et-asset et-docket';
    gap: 0.5rem;
    height: calc(100% - var(--title-container-height));
    grid-template-columns: 1fr 1fr 1fr 1.5fr 1fr;
    grid-auto-rows: 1fr 1fr;
    --card-header-height: 3rem;
}

.edit-ticket > div > div:first-child {
    height: var(--card-header-height);
}

.edit-ticket > div > div:nth-child(2) {
    max-height: calc(100% - var(--card-header-height));
    overflow-y: auto;
}

.edit-ticket-ticket {
    grid-area: et-ticket;
}

.edit-ticket-site {
    grid-area: et-site;
}

.edit-ticket-asset {
    grid-area: et-asset;
}

.edit-ticket-partner {
    grid-area: et-partner;
}

.edit-ticket-docket {
    grid-area: et-docket;
}

.edit-ticket-misc {
    grid-area: et-misc;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type='number'] {
    -moz-appearance: textfield;
}

.projectDetailsHeight {
    height: calc(100vh - (var(--header-height) + var(--footer-height) + var(--project-sidebar-header)));
}

.projectDetailsSidebarheight {
    height: var(--project-sidebar-header);
}

.MuiStepConnector-root {
    margin-left: 8px !important;
}

.scrollableTableHeader {
    height: var(--table-header-height);
}

.scrollableTableBodyHeight {
    height: calc(100% - var(--table-header-height));
    border-radius: inherit;
}
.BaseTable__table-frozen-left .BaseTable__header-cell:first-child,
.BaseTable__table-frozen-left .BaseTable__row-cell:first-child {
    padding-left: 0px !important;
}

.BaseTable__table-frozen-right .BaseTable__header-cell:last-child,
.BaseTable__table-frozen-right .BaseTable__row-cell:last-child {
    padding-right: 0px !important;
}
.BaseTable__table-main > .BaseTable__header {
    height: 32px !important;
}

.BaseTable__body::-webkit-scrollbar {
    height: 6px !important;
    border-radius: 100px;
}

.chatSuHeaderTop {
    position: absolute;
    top: var(--chat-containers-header);
}

.chatSubHeaderBody {
    height: calc(100% - var(--chat-containers-header) + 1rem);
}

.editTicketWithChat {
    height: calc(100% - var(--title-container-height));
    width: 100%;
    display: flex;
}

.edit-ticket-card-wtih-chat {
    width: 25%;
    height: 100%;
}

.chat-card-wtih-edit-ticket {
    grid-area: edwc-chat;
    width: 75%;
}

.renderChatIcon {
    display: none;
}

.mainContainerForUnauthorisedUser {
    position: fixed;
    height: calc(100% - (var(--header-height) + 0.1rem));
    width: 100vw;
    top: var(--header-height);
    display: flex;
}

@media (max-width: 1024px) {
    .renderSearchIcon {
        display: none;
    }
    .header {
        padding: 0% 3% 0% 3%;
    }
    .edit-ticket-card-wtih-chat {
        grid-area: edwc-details;
        width: 100%;
    }
    .chat-card-wtih-edit-ticket {
        grid-area: edwc-chat;
        width: 100%;
    }
    .renderChatIcon {
        display: block;
    }
    .labelFotnSize {
        font-size: 13px !important;
    }
    .inputFontSize {
        font-size: 12px !important;
    }
    .submitButtonSupportText {
        font-size: 15px;
    }
}

#otpless-floating-button {
    position: fixed !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
}

.groupViewTableContainer {
    box-sizing: border-box;
    height: calc(100% - (var(--title-container-height) + var(--pagination-gap)));
    width: 100%;
}

.groupViewTableBody {
    box-sizing: border-box;
    height: calc(100% - 5vh);
    width: 100%;
}

.material-icons {
    user-select: none;
}

.no-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.markdown-table-wrapper table {
    width: 100%;
    border-collapse: collapse;
    margin: 1rem 0;
    font-size: 1rem;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
}

.markdown-table-wrapper th,
.markdown-table-wrapper td {
    padding: 8px 12px;
    border: 1px solid #ddd;
    text-align: left;
}

.markdown-table-wrapper th {
    background-color: #f4f4f4;
    font-weight: bold;
}

.markdown-table-wrapper tr:nth-child(even) {
    background-color: #f2f2f2;
}

.markdown-table-wrapper tr:hover {
    background-color: #e9e9e9;
}
