@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

/* @layer base {
    h1{
        font-family: 'Rubik', sans-serif;
    }
} */

.tab-all {
    @apply rounded-full py-1 px-6 border border-scogoddd mr-3 cursor-pointer text-scogogray text-font13 font-medium;
}

.tab-all-active {
    @apply rounded-full text-white py-1 px-6 bg-scogoorange border border-scogoorange mr-3 text-font13 cursor-pointer font-medium;
}

.tab-card {
    @apply rounded-15px py-1 2xl:px-4 px-2 border border-scogoddd cursor-pointer text-scogogray text-font12 font-medium flex items-center whitespace-nowrap hover:text-white hover:bg-scogoorange;
}

.tab-card-active {
    @apply rounded-15px text-white py-1 2xl:px-4 px-2 bg-scogoorange border border-scogoorange text-font12 cursor-pointer font-medium flex items-center whitespace-nowrap;
}

.status-tab {
    @apply text-white font-normal text-font13 px-3 rounded-full;
}

/*-------- login input class -------*/
.loginput {
    @apply border w-full text-scogo15 rounded-md py-2 pl-12 pr-4 focus:outline-none mx-auto border-scogoccc focus:ring-r-none;
}

.loginput-maindiv {
    @apply text-gray-400 focus-within:text-gray-600 relative;
}

.loginput-imgdiv {
    @apply absolute;
}

.css-g1d714-ValueContainer {
    @apply p-0 !important;
}

.css-yk16xz-control {
    @apply border-0 !important;
}

.css-1pahdxg-control {
    @apply border-0 shadow-none !important;
}

.select__input input {
    @apply ring-0 !important;
}

.css-1wa3eu0-placeholder {
    @apply text-scogo99 font-normal text-font15;
}

.css-1rhbuit-multiValue {
    @apply rounded-30px px-2 !important;
}

.css-g1d714-ValueContainer .css-tj5bde-Svg {
    @apply rounded-full border border-black;
}

/* .css-g1d714-ValueContainer .css-tj5bde-Svg{
    @apply hidden ;
} */

.css-tlfecz-indicatorContainer .css-tj5bde-Svg {
    @apply hidden !important;
}

.css-1gtu0rj-indicatorContainer .css-tj5bde-Svg {
    @apply hidden !important;
}

.css-tlfecz-indicatorContainer {
    @apply text-scogo99 !important;
}

.css-1okebmr-indicatorSeparator {
    @apply hidden !important;
}

.css-12jo7m5 {
    @apply text-font13 font-normal text-scogo15 !important;
}

.css-1uccc91-singleValue {
    @apply text-font15 text-scogo15 font-normal !important;
}

.chatsingle-tab {
    @apply pl-p-23 py-p-8 pr-p-6;
}

.stausdefault {
    @apply top-4 left-0 px-p-10 py-p-6 leading-6 text-center rounded-r-5px absolute;
}

.css-8mmkcg {
    @apply hidden;
}

.proof-upload input[type='file']::file-selector-button {
    @apply hidden !important;
}

.td_p_dark {
    @apply text-scogo15 2xl:text-font13 text-font11 font-medium;
}

.td_p_light {
    @apply text-scogogray 2xl:text-font13 text-font11 font-normal;
}

.td_p_light_primary {
    @apply text-scogoprimary 2xl:text-font13 text-font11 font-semibold;
}

/*-------- login input class -------*/
.writehere {
    @apply border w-full text-scogo15 rounded-md h-7 focus:outline-none mx-auto border-scogoccc focus:ring-r-none text-font12;
}

@media (max-width: 1920px) {
    .adjust-media {
        @apply pb-5;
    }
}

@media (max-width: 1680px) {
    .adjust-media {
        @apply pb-10;
    }

    .chat-bottom {
        @apply pb-7;
    }
}

/* Loader Styling */
/* Safari */
@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@media screen and (max-height: 591px) {
    .custom-scroll {
        height: calc(100vh - 54px) !important;
        overflow-y: scroll !important;
    }
}

.custom-chat-scroll {
    height: calc(100vh - 295px) !important;
}

.custom-chat-scroll-2 {
    height: calc(100vh - 352px) !important;
}

.custom-chat-scroll-3 {
    height: calc(100vh - 158px) !important;
}

/* Custom Toggle for Mute Notifications */

.toggle-bg:after {
    content: '';
    @apply absolute top-1 left-0.5 bg-white border border-gray-300 rounded-full h-6 w-6 transition shadow-sm;
}

input:checked + .toggle-bg:after {
    transform: translateX(100%);
    @apply border-white;
}

input:checked + .toggle-bg {
    @apply bg-scogoorange;
}

input:checked + #toggle-bg {
    @apply bg-scogoprimary;
}

/* adding for removing default padding of Select Dropdown */
.css-1fdsijx-ValueContainer {
    padding: 2px 0px !important;
}

.scogoGptClass > ul,
ol,
menu {
    margin: 1rem;
}

.scogoGptClass > ul {
    list-style-type: disc;
    list-style-position: outside;
    margin-left: 2rem;
}

.scogoGptClass > ol {
    list-style-type: decimal;
    list-style-position: outside;
    margin-left: 2rem;
}

.scogoGptClass > p {
    margin: 0.5rem;
}

.thinkingText {
    font-family: 'Inter', sans-serif;
    font-size: 1.4rem;
    font-weight: 500;
    color: #000;
    display: flex;
    align-items: center;
}

.shiny-text {
    background: linear-gradient(90deg, #000, #333, #666, #999, #ccc, #999, #666, #333);
    background-size: 200%;
    background-clip: text;
    color: transparent;
    animation: shiny 3s infinite linear;
    font-size: large;
    font-weight: 400;
}

@keyframes shiny {
    0% {
        background-position: 200% 0;
    }
    100% {
        background-position: -200% 0;
    }
}


.messageWrapper {
    display: flex;
    margin-bottom: 0.6rem;
    position: relative;
    gap: 0.8rem;
}
