@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";


html {
    font-size: 62.5%;
}

/* @media only screen and (max-width: 600px) {
    
}


@media only screen and (min-width: 600px) {

}


@media only screen and (min-width: 768px) {

}


@media only screen and (min-width: 992px) {
} */


@media only screen and (min-width: 1200px) {

    html {
        font-size: 62.5%;
    }
}

@media only screen and (min-width: 1400px) {

    html {
        font-size: 70%;
    }
}

@media only screen and (min-width: 1700px) {

    html {
        font-size: 80%;
    }
}

@media only screen and (min-width: 2000px) {

    html {
        font-size: 95%;
    }
}


.mainDivsPadding {
    padding: 1.5rem 1.5rem;
}

.mainBody {
    margin-top: 4.6rem;
    margin-left: 7rem;
    width: 100%
}

.inVentoryMainBody {
    margin-top: 4.6rem;
    margin-left: 2rem;
    margin-bottom: 20rem;
    border: 2px solid yellowgreen;
    height: 100vh
}

.tablePadding {
    padding: 0 1.5rem;
}

.list-item {
    position: relative;
    margin: 0 6px 12px 6px;
    padding: 16px;
    list-style-type: none;
}

.radio-card input:checked {
    background-image: linear-gradient(180deg, #F48A21, #F48A21);
    color: #fff;
    outline: none;
}

.radio-card input:before {
    content: attr(label);
}

.card {
    background: white;
    border-radius: 3px;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
}
.clamp {
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    overflow-wrap: break-word;
}

.checkbox-card>input[type=checkbox]:checked~span {
    background-color: #F48A21 !important;
    color: #fff;
    border: 1px solid #F48A21;
}
.circular-progress {
    position: relative;
    height: 80%;
    width: 100%;
    border-radius: 50%;
    display: grid;
    place-items: center;
}


.circular-progress:before {
    content: "";
    position: absolute;
    height: 84%;
    width: 84%;
    background-color: #fff;
    border-radius: 50%;
}

.value-container {
    position: relative;
    font-size: 2.2rem;
    color: #231c3d;
}
@-o-keyframes fadeIt {
    0% {
        background-color: #FFFFFF;
    }

    50% {
        background-color: #AD301B;
    }

    100% {
        background-color: #FFFFFF;
    }
}

@keyframes fadeIt {
    0% {
        background-color: #2280e4;
    }

    50% {
        background-color: #64a7ee;
    }

    100% {
        background-color: transparent;
    }
}

.highLightDiv {
    -o-animation: fadeIt 5s ease-in-out;
    animation: fadeIt 5s ease-in-out;
}
.ribbon {
    position: relative;
    margin-bottom: 30px;
    background-size: cover;
    text-transform: uppercase;
    color: white;
}


.ribbon3 {
    padding-left: 15px;
    padding-right: 10px;
    padding-top: 3px;
    padding-bottom: 3px;
    position: absolute;
    left: -15px;
    top: 4px;
}

.ribbon3:before,
.ribbon3:after {
    content: "";
    position: absolute;
}

.ribbon3:before {
    height: 0;
    width: 0;
    left: 0.1px;
    bottom: -8px;
    border-top: 9px solid black;
    border-left: 9px solid transparent
}

.ribbon3:after {
    top: 0px;
    right: -14.5px;
    border-top: 1rem solid transparent;
        border-bottom: 1.1rem solid transparent;
}

.ribbonblue:after {
    border-left: 15px solid #3498DB;
}

.zoom {
    transition: transform .2s;
    cursor: pointer;
}

.zoom:hover {
    transform: scale(1.04);
        background-image: linear-gradient(to right top, #2199e9, #0086f1, #0071f5, #0058f3, #1636eb);
    border: #F48A21;
    color: white;

}


.greenlinear-gradient:hover {
    background: #73d7cfe6;
    /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #38ef7d, #17ba7d);
    /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #38ef7d, #17ba7d);

}

.redlinear-gradient:hover {
    background: #CB356B;
    /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #f26858d6, #e41561c9);
    /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #f26858d6, #e41561c9);
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */


}

.border-scogoBasicYellow {
    border: #f1e000 solid 1px;
    border-left: #f1e000 solid 4px;
}

.text-scogoBasicYellow {
    color: #f1e000;
}
.labelClass:hover .childDiv {
    display: block;
}
.ticketNumber:hover .chatIcon {
    display: block;
}
.dialPad {
    padding-left: 0rem !important;
    width: auto !important;
    border-top: none;
    border-left: none;
    border-right: none;
}

@keyframes background-fade {
    99.9% {
        background: rgba(47, 47, 47, 0.2);
    }

    100% {
        background: transparent;
    }
}

.dialPadNumber {
    display: inline;
    animation: background-fade 0.11s forwards;
}